<template>
  <div class="ctn-b">
    <div class="bg-image">
      <div class="bg-image-overlay"></div>
    </div>
    <div>
      <span>USERNAME:</span>
      <input type="text" v-model="username" />
    </div>
    <div>
      <span>PASSWORD:</span>
      <input type="password" v-model="password" />
    </div>
    <div class="error" v-if="showError">
      <span>账号或者密码错误</span>
    </div>
    <div>
      <div class="btn" @click="login">登陆</div>
    </div>
  </div>
</template>

<script>
import storage from "@/storage";
export default {
  data() {
    return {
      username: "",
      password: "",
      showError: false
    };
  },
  mounted() {},
  methods: {
    login() {
      let that = this
      storage.login(
        { username: this.username, password: this.password },
        function(result) {
          if (result) {
            that.$router.push("/edit");
          } else {
            this.showError = true;
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.error > span {
  font-size: 0.8rem;
  color: red;
}
.btn {
  margin-top: 2rem;
  width: 10rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 255, 255, 0.336);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

input {
  border-width: 0 0 0.1rem 0;
  margin-bottom: 1rem;
  outline: none;
  text-align: center;
}

span {
  color: white;
  margin-right: 1rem;
}
</style>
<style>
.ctn-b {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
</style>