<template>
  <div class="ctn">
    <div class="item">
      <div class="icon">
        <img class="icon-img" src="@/assets/design.png" />
      </div>
      <div>
        <div>
          <div>987</div>
          <div>设计方案</div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="icon-img" src="@/assets/time.png" />
      </div>
      <div>
        <div>
          <div>987</div>
          <div>从业时间</div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="icon-img" src="@/assets/star.png" />
      </div>
      <div>
        <div>987</div>
        <div>个人项目</div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="icon-img" src="@/assets/heart.png" />
      </div>
      <div>
        <div>
          <div>987</div>
          <div>收获好评</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ctn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-image: url("@/assets/bg-pattern.jpg");
}
.item {
  color: rgb(255, 255, 255);
  width: 25%;
  display: flex;
  justify-content: center;
}

.icon-img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
@media screen and (max-width: 992px) {
  .ctn {
    display: table;
  }
  .item {
    display: table-cell;
    vertical-align: middle;
  }
  .icon-img {
    margin-right: 0;
  }

}
</style>