<template>
  <div class="ctn">
    <div class="info">
      <div class="top">{{ words.part2Title }}</div>
      <div class="detail">
          {{ words.part2Content }}
      </div>
      <div class="sign">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    words: {
      type: Object
    }
  },
};
</script>

<style scoped>
.info {
  widows: 4rem;
  height: 4rem;
  width: 100%;
  color: black;
  height: fit-content;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.info > div {
  margin-bottom: 1rem;
}
.top {
  font-weight: bold;
  font-size: 2rem;
}
.detail{
  font-size: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>