<template>
  <div class="ctn flex">
    <div class="cover">
      <img class="cover" :src="diary.cover" />
    </div>
    <div class="d-name">
      <span>{{ diary.name }}</span>
    </div>
    <div class="d-des">
      <span>{{ diary.description }}</span>
    </div>

    <div id="content" class="d-contant"></div>
    <div class="d-time">
      <span>{{ format(diary.createtime) }}</span>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      id: -1,
      diary: {}
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.initPage();
  },
  methods: {
    initPage() {
      let that = this;
      axios.post({ id: this.id }, "/diary/one").then(res => {
        console.log(res);
        that.diary = res.data;
        $("#content").html(that.diary.content);
      });
    },
    format(timestamp) {
      var time = new Date(timestamp);
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    }
  }
};
</script>

<style >
</style>
<style scoped>
.ctn {
  color: black;
  padding-top: 12rem;
  flex-direction: column;
}
.cover {
  width: 20rem;
  height: 20rem;
}
.d-name {
  font-size: 1.2rem;
  margin-top: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.d-contant {
  max-width: 90vw;
}
.d-time {
  margin-bottom: 20rem;
  display: flex;
  width: 90vw;
  justify-content: flex-end;
}
</style>