<template>
  <div>
    <PageA :words="words"/>
    <PageB :words="words"/>
    <PageC/>
    <PageD :words="words"/>
    <PageE/>
    <PageF />
    <PageG :words="words"/>
    <PageH />
  </div>
  
</template>

<script>
import axios from "@/axios/request";
import PageA from "@/components/front/PageA.vue";
import PageB from "@/components/front/PageB.vue";
import PageC from "@/components/front/PageC.vue";
import PageD from "@/components/front/PageD.vue";
import PageE from "@/components/front/PageE.vue";
import PageF from "@/components/front/PageF.vue";
import PageG from "@/components/front/PageG.vue";
import PageH from "@/components/front/PageH.vue";
export default {
  components: {
    PageA,PageB,PageC,PageD,PageE,PageF,PageG,PageH
  },
  data() {
    return {
      words:{}
    }
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage(){
      let that = this
      axios.get("/index/words/list").then(res=>{
        that.words = res.data
      })
    }
  },
}

</script>

<style>

</style>