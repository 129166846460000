<template>
  <div class="ctn">
    <div class="d-item" v-for="item in list" :key="item">
      <div class="left">
        <div class="time flex">{{format( item.createtime) }}</div>
        <div class="part part-l flex">
          <div class="dot"></div>
        </div>
      </div>
      <div class="right">
        <router-link :to="{path:'/diary',query:{id:item.id}}">
          <div class="doc doc-name">{{ item.name }}</div>
          <div class="doc doc-des">{{ item.description }}</div>
          <div class="bor-line"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    $(".d-item").mouseenter(function() {
      $(this).addClass("active");
    });
    $(".d-item").mouseleave(function() {
      $(this).removeClass("active");
    });
    this.initPage();
  },
  methods: {
    initPage() {
      axios.post({}, "/diary/list").then(res => {
        this.list = res.data;
      });
    },
    format(timestamp) {
      var time = new Date(timestamp);
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    }
  }
};
</script>

<style scoped>
.d-item {
  margin-left: 2rem;
  width: 100%;
  display: flex;
}
.dot {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: rgb(10, 10, 10);
  border-radius: 50%;
  margin-right: -0.5rem;
}

.d-item:hover > .left > .part > .dot {
  width: 1.2rem;
  height: 1.2rem;
  background-color: red !important;
}

.bor-line {
  width: 100%;
  height: 1rem;
}
.d-item:hover > .right > a > .bor-line {
  animation-name: grow;
  animation-duration: 4s;
  animation-iteration-count: 1;
  border-width: 0 0 0.1rem 0;
  border-color: red;
  border-style: solid;
}

@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.d-item.active > .left > .part > .dot {
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgb(0, 0, 0);
  margin-right: -0.5rem;
}
.d-item.active > .left > .time {
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.d-item.active > .right > a {
  color: rgb(0, 0, 0);
}

.part {
  width: 0.5rem;
  height: 100%;
}
.part-l {
  border-width: 0 1px 0 0;
  border-color: black;
  border-style: solid;
}

.left {
  width: 30vw;
  position: relative;
  display: flex;
  justify-content: end;
  cursor: pointer;
}
a {
  color: rgb(0, 0, 0);
}
.right {
  width: 70vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  cursor: pointer;

  padding-right: 2rem;
}
.doc {
  width: 100%;
  text-align: start;
}
.doc-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.ctn {
  color: black;
  padding-top: 12rem;
  display: flex;
  flex-direction: column;
}
.time {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
  width: 10rem;

}
</style>