<template>
  <div class="ctn">
    <swiper
      :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
      }"
      class="mySwiper"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      @progress="1"
      :loop="true"
      :autoplay="autoplay"
      :modules="modules"
      :pagination="{ clickable:true}"
      :scrollbar="{ draggable: true }"
      @setTransition="1"
    >
      <swiper-slide v-for="item in swipers" :key="item" ><div class="words" >{{ item.words }}</div></swiper-slide>
    </swiper>
  </div>
</template>
<script>

import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import axios from "@/axios/request";
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      autoplay: {
        delay: 1500
      },
      swipers:[]
    };
  },
  mounted() {
    this.initPage()
  },
  methods: {
    initPage(){
      axios.get("/index/words/swiper/list").then(res=>{
        this.swipers = res.data
        console.log(res)
      })
    }
  },


  setup() {
    const onSwiper = () => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
    };
  }
};
</script>

<style scoped>
.swiper {
  position: static;
}
.words{
  margin-left: 2rem;
  margin-right: 2rem;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  color: white !important;
}
.swiper-container {
  width: 100%;
  height: 6rem;
}
.ctn {
  width: 100%;
  height: 26rem;
  background-image: url("@/assets/bg-pattern.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.swiper-slide {
  cursor: pointer;

}

@media screen and (max-width: 950px){
  .ctn{
    height: 20rem;
  }
}
</style>
<style >

</style>