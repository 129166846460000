<template>
  <div class="ctn">
    <div class="img-box">
      <div
        :class="item.id==activePosition?'img-item active':'img-item'"
        v-for="item in list"
        :key="item.id"
        @mouseenter="enter(item.id)"
        @mouseleave="leave()"
      >
        <router-link :to="{path:'/caseDetail',query:{
          id:item.id
        }}">
          <div class="img-info">
            <div class="ti">{{ item.name }}</div>
            <div class="de">{{item.description}}</div>
          </div>
        </router-link>
        <img :src="item.cover" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      activePosition:-1,
      list: [
       
      ]
    };
  },
 
  mounted() {
    this.initPage();
  },
  methods: {
    enter(position) {
      this.activePosition =position
    },
    leave() {
      this.activePosition = -1
    },
    initPage() {
      axios.get("/styles/list").then(res => {
        this.list = res.data;
        console.log(this.list);
      });
    }
  }
};
</script>

<style scoped>
.ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.img-box {
  width: 65%;
  display: table;
}
.img-item {
  position: relative;
  padding: 0;
  width: 20vw;
  height: 20vw;
  display: inline-table;
  align-self: center;
  justify-content: center;
  float: left;
  font-size: 0;
}

.ti {
  font-size: 1.5rem;
  font-weight: bold;
}
.de {
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
}
.img-item > img {
  width: 20vw;
  height: 20vw;
  object-fit: cover;
  background-size: cover;
}
</style>

<style >
.img-info {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation-name: mo;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.img-item.active > a > .img-info {
  animation-name: mi;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.img-item.active > a > .img-info > .ti {
  animation-name: mif;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.img-item.active > a > .img-info > .de {
  animation-name: mif;
  animation-duration: 1s;

  animation-fill-mode: both;
  animation-delay: 0.2s;
}
@keyframes mif {
  0% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mi {
  0% {
    background-color: rgba(2, 2, 2, 0);
    color: rgba(165, 42, 42, 0);
  }
  100% {
    background-color: rgba(2, 2, 2, 0.712);
    color: rgba(235, 231, 231, 0.959);
  }
}
@keyframes mo {
  0% {
    background-color: rgba(2, 2, 2, 0.712);
    color: rgba(235, 231, 231, 0.904);
  }
  100% {
    background-color: rgba(2, 2, 2, 0);
    color: rgba(165, 42, 42, 0);
  }
}

@media screen and (max-width: 992px) {
  .img-item > img {
    width: 70vw;
    height: 70vw;
  }
  .img-item {
    width: 59vw;
    height: 59vw;
    margin-bottom: 1rem;
  }
}
</style>