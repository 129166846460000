<template>
  <div class="ctn-e">
    <div class="e-title" v-for="(item,idx) in styles" :key="item">
      <div class="tit">图1</div>
      <div>
        <img class="img" :src="item.cover" />
      </div>
      <div class="e-p">
        <span class="h">封面：</span>
        <input
          :id="'saveImage'+idx"
          cols="30"
          rows="10"
          type="file"
          accept="image/jpg, image/png"
          @change="imgOnChange(idx)"
        />
      </div>
      <div class="e-p">
        <span class="h">名称：</span>
        <input name="homeE" id="homeE" cols="30" rows="10" v-model="item.name" />
      </div>
      <div class="e-p">
        <span class="h">描述：</span>
        <input name="homeE" id="homeE" cols="30" rows="10" v-model="item.description" />
      </div>
      <div class="e-p">
        <button @click="puttop(idx)">置顶</button>
        <button @click="deleteStyle(idx)">删除</button>
      </div>
    </div>

    <div class="e-title btn-pannel">
      <div class="btn add-btn" @click="add">add</div>
      <div class="btn" @click="save">save</div>
    </div>
  </div>
</template>


<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      styles: []
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      axios.get("/styles/list").then(res => {
        this.styles = res.data;
        $(".add-btn").removeClass("hidden");
      });
    },
    add() {
      this.styles.push({});
      $(".add-btn").addClass("hidden");
    },
    deleteStyle(idx) {
      let id = this.styles[idx].id;
      if (undefined == id) {
        this.styles.pop();
        this.initPage();
      } else {
        axios.post({ id: id }, "/styles/delete").then(() => {
          this.initPage();
        });
      }
    },
    puttop(idx) {
      this.styles[idx].createtime = new Date().valueOf();
      this.save();
    },
    save() {
      for (let index = 0; index < this.styles.length; index++) {
        const element = this.styles[index];
        if (element.id != undefined) {
          axios.post(element, "/styles/update").then(() => {
            this.initPage();
            if (index == this.styles.length - 1) {
              alert("更新完成");
            }
          });
        } else {
          axios.post(element, "/styles/add").then(() => {
            this.initPage();
            if (index == this.styles.length - 1) {
              alert("更新完成");
            }
          });
        }
      }
    },
    /* 图片上传 */
    imgOnChange(idx) {
      let id = "#saveImage" + idx;
      if ($(id)[0] == undefined) {
        return;
      }
      let file = $(id)[0].files[0];
      let url = URL.createObjectURL(file);
      console.log(url);
      axios.uploadImage(file, "/load/up/image").then(res => {
        console.log(res);
        this.uploadCallBack(res, idx);
      });
    },
    /* 图片上传callBack */
    uploadCallBack(res, idx) {
      if (res.code == 200) {
        //上传成功
        let url = res.data.path;
        // $("#good-img").attr("src", url);
        this.styles[idx].cover = url;
      }
    }
  }
};
</script>

<style scoped>
input {
  outline: none;
  border-style: solid;
  /* border-color: rgba(0, 0, 0, 0.226); */
  border-width: 0 0 0rem 0;
  font-size: 1rem;
  width: -webkit-fill-available;
  background-color: rgba(112, 112, 112, 0);
}
input:focus {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 0 0 0.1rem 0;
  background-color: rgba(253, 252, 252, 0.767);
}
.e-title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.137);
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0.356);
  padding-bottom: 1rem;
}
.tit {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
}
.e-p {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}
.h {
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
}

.ctn-e {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn {
  background-color: rgb(96 96 96);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  width: 80%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.btn-pannel {
  padding: 0;
  margin-bottom: 10rem;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0);
  display: flex;
  align-items: center;
}
el-select {
  width: 5rem;
}
button {
  margin-right: 2rem;
}
img {
  width: 16rem;
  height: 16rem;
}
</style>