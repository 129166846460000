<template>
  <div class="ctn-b">
    <div class="bg-image">
      <div class="bg-image-overlay"></div>
    </div>
    <div class="menu">
      <div :class='contant==1?"option-1 active":"option-1"'  v-on:click="clickMenu(1)">主页文字内容</div>
      <div :class='contant==2?"option-1 active":"option-1"' v-on:click="clickMenu(2)">设计方案九宫格</div>
      <div :class='contant==5?"option-1 active":"option-1"' v-on:click="clickMenu(5)">设计方案图集</div>
      <!-- <div :class='contant==5?"option-1 active":"option-1"' v-on:click="()=>{this.contant=5}">轮播图介绍</div> -->
      <div :class='contant==3?"option-1 active":"option-1"' v-on:click="clickMenu(3)">家装日志</div>
      <div :class='contant==4?"option-1 active":"option-1"' v-on:click="clickMenu(4)">编辑个人信息</div>
      <div :class='contant==10?"option-1 active":"option-1"' @click="logout">退出登陆</div>
      <div class="btn-sh">
        <img class="ico-m" :src="icon_m" v-on:click="clickRL" />
      </div>
    </div>
    <div class="main-panel">
        <EdHome v-if="contant==1"></EdHome>
        <EdStyle v-if="contant==2"></EdStyle>
        <DiaryList v-if="contant==3" @createDiary="createDiary" @editDiary="editDiary"></DiaryList>
        <EdUser v-if="contant==4"></EdUser>
        <StyleImg v-if="contant==5"></StyleImg>
        <EdDiary v-if="contant==6" :diaryId="diaryId" @finishDiary="finishDiary"></EdDiary>
    </div>
  </div>
</template>

<script>
import EdHome from "@/components/back/edit/EdHome.vue"
import EdStyle from "@/components/back/edit/EdStyle.vue";
import DiaryList from "@/components/back/edit/DiaryList.vue";
import EdUser from "@/components/back/edit/EdUser.vue"
import StyleImg from "@/components/back/edit/StyleImg.vue";
import EdDiary from "@/components/back/edit/EdDiary.vue"

import storage from "@/storage";
export default {
  components:{
    EdHome,EdStyle,DiaryList,EdUser,StyleImg,EdDiary
  },
  data() {
    return {
      shouMenu: true,
      icon_m: require("@/assets/img/toL.png"),
      contant: 0,
      diaryId:-1
    };
  },
  mounted() {

  },
  methods: {
    finishDiary(){
      this.contant=3
    },
    editDiary(id){
      this.contant=6
      this.diaryId = id
    },
    createDiary(){
      this.contant=6
    },
    clickMenu(index){
      this.contant=index;
      this.clickRL();
    },
    logout(){
      storage.logout();
    },
    clickRL() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        $(".menu").addClass("hide");
        this.icon_m = require("@/assets/img/toR.png");
      } else {
        $(".menu").removeClass("hide");
        this.icon_m = require("@/assets/img/toL.png");
      }
    }
  }
};
</script>

<style scoped>

.menu {
  z-index: 999;
  color: white;
  position: absolute;
  left: 0;
  width: 9rem;
  background-color: rgba(0, 0, 0, 0.411);
}
.menu.hide {
  left: -9rem;
}

.menu.hide > .btn-sh {
  height: 3rem;
}

.option-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}
.btn-sh {
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  border-radius: 0 0.3rem 0.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1.5rem;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.411);
}
.ico-m {
  width: 1rem;
  animation-name: shan;
  animation-duration: 1s;
  animation-iteration-count: 10000;
}
.option-1.active{
    background-color: rgba(255, 255, 255, 0.726);
    color: rgb(51, 51, 51);
}



.main-panel{
    background-color: white;
    position: absolute;
    top: 10rem;
    left: 14rem;
    right: 1rem;
    bottom: 1rem;
    border-radius: 1rem;
}

@media screen and (max-width: 900px){ 
    .main-panel{
    background-color: white;
    position: absolute;
    top: 10rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    border-radius: 1rem;
}  
}
@keyframes shan {
  0% {
    width: 1rem;
  }
  50% {
    width: 1.2rem;
  }
  100% {
    widows: 1rem;
  }
}
</style>