<template>
  <div class="ctn">
    <div class="top">
      <div class="left">
        <div>
          <span class="h2">联系方式</span>
        </div>
        
      </div>
      <div>
          <span class="hd">Name:</span>
          <span class="de">{{user.name}}</span>
        </div>
      <div>
        <span class="hd">Address:</span>
        <span class="de">
          重庆，成都
        </span>
      </div>
      <div>
        <span class="hd">Phone:</span>
        <span class="de">{{user.phone}}</span>
      </div>
      <div>
        <span class="hd">Email:</span>
        <span class="de">{{user.email}}</span>
      </div>
      <div class="right"></div>
    </div>
    <!-- <div class="bt">
      <div class="btn" >留言</div>
    </div> -->
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      user: {
      }
    };
  },
  mounted() {
    this.initPage()
  },
  methods: {
    initPage(){
      let that = this
      axios.get("/info/user/one").then(res=>{
        console.log(res)
        that.user = res.data
      })
    }
  },
};
</script>

<style scoped>
.ctn {
  margin-top: 3rem;
  color: black;
  display: flex;
  flex-direction: column;
}
.h2 {
  font-size: 2rem;
  font-weight: bold;
}
.hd {
  font-size: 1rem;
  font-weight: bold;
}
.left > div {
  margin-bottom: 1rem;
}
.top{
  margin-bottom: 5rem;
}
.hd {
  margin-right: 1rem;
}
.bt {
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  background-color: black;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
}
</style>