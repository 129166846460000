<template>
  <div class="view-img-box flex" v-if="showAble">
    <div class="cancel" v-on:click="close">
      <img class="cancel" src="@/assets/cancel.png" alt />
    </div>
    <div class="img">
      <img class="img" :src="path" alt />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showAble: {
      type: Boolean,
      require: true,
    },
    path: {
      type: String,
      require: true,
      default: require("@/assets/img/biz/3.png")
    }
  },
  data() {
    return {
    };
  },
  mounted(){

  },
  methods: {
    open(){
      $('body').css({ "overflow-x":"hidden", "overflow-y":"hidden" });
    },
    close() {
     $('body').css({ "overflow-x":"auto", "overflow-y":"auto" });
     this.$emit("clossImgView")
    },
   
  }
};
</script>

<style>

div.img {
  display: flex;

}
img.img {
  max-width: 100vw;
  max-height: 100vh;
}
.cancel {
  width: 2rem;
  height: 2rem;
  z-index: 5;
}
img.cancel {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.view-img-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}
</style>