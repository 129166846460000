<template>
  <div class="ctn">
    <div class="list">
      <div class="diary" v-for="item in diary" :key="item">
        <div class="name" @click="goDiary(item.id)">{{ item.name }}</div>
        <div class="time">{{ format(item.createtime) }}</div>
        <div class="btns-a">
          <button @click="pushTop(item.id)">置顶</button>
          <button @click="editDiary(item.id)">编辑</button>
          <button @click="deleteDiary(item.id)">删除</button>
        </div>
      </div>
    </div>
    <div class="btns flex">
      <button @click="createDiary()">创建日志</button>
    </div>
  </div>
</template>
  
  
  
  <script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      diary: {}
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    goDiary(id){
      this.$router.push({path:'/diary',query:{id:id}})
    },
    format(timestamp) {
      var time = new Date(timestamp);
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    initPage() {
      axios.post({}, "/diary/list").then(res => {
        this.diary = res.data;
      });
    },
    createDiary() {
      this.$emit("createDiary");
    },
    editDiary(id) {
      this.$emit("editDiary", id);
    },
    deleteDiary(id) {
      axios.post({ id: id }, "/diary/delete").then(res => {
        if(res.data==1){
          alert("删除成功")
          this.initPage()
        }
      });
    },
    pushTop(id){
      axios.post({"id":id,"top":new Date().valueOf()},"/diary/update").then(res=>{
        if(res.data==1){
          this.initPage()
        }
      })
    }
  }
};
</script>
  
  <style scoped>
  .time{
    font-size: 0.8rem;
  }
.name {
  text-decoration: underline;
  margin-right: 2rem;
  cursor: pointer;
}
.diary {
  display: flex;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
button {
  margin-left: 0.1rem;
}
.ctn {
  color: black;
  flex-direction: column;
}
.btns {
  margin: 2rem;
}
.list {
  margin: 2rem;
  min-height: 2rem;
}
.btns-a {
  margin-left: 2rem;
}
</style>