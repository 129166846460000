const TOKEN_KEY = "token";
import axios from "@/axios/request";
export default {
    get(key) {
        return localStorage.getItem(key);
    },
    save(key, value) {
        localStorage.setItem(key, value)
    },
    saveToken(token){
        localStorage.setItem(TOKEN_KEY,token)
    },
    tokenExist() {
        return localStorage.getItem(TOKEN_KEY) != null
    },
    removeToken(){
        localStorage.removeItem(TOKEN_KEY)
    },

    validToken(callBack){
        if(this.tokenExist){
            let token = this.get(TOKEN_KEY);
            axios.post({"token":token},"/user/valid").then(res=>{
                if(res.code==200){
                    callBack(true)
                }else{
                    this.removeToken
                    callBack(false)
                }
            })
        }
    },
    login(user,callBack){
        axios.post(user,"/user/check").then(res=>{
            if(res.code==200){
                this.saveToken(res.data.token)
                callBack(true)
            }else{
                callBack(false)
            }
        })
    },
    logout(){
        this.removeToken()
        window.location.reload()
    }

}