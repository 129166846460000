<template>
  <div v-if="count!=0" class="ctn">
    <div class="progress">
      <div class="load">loading...</div>
      <div class=" progress-bar flex an"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
      require: true
    },
    current: {
      type: Number,
      default: 0,
      require: true
    }
  },
  data(){
    return{
      
    }
  },
  watch: {
    count() {
      if(this.count>0 && this.current != this.count){
        this.show()
      }
    },
    current() {
      if(this.count == this.current){
        this.hide()
        this.$emit("finish")
      }else{
        let string = this.current/this.count*10<<0
        $(".progress-bar").addClass("an"+string)
      }
    }
  },
  methods:{
    show(){
      $(".ctn").removeClass("hidden");
    },
    hide(){
      $(".ctn").addClass("hidden")
    }
  }
};
</script>

<style scoped>
.ctn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.473);
}
.progress {
  position: absolute;
  width: 60%;
  height: 2rem;
  background-color: white;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.progress-bar {
  position: absolute;
  height: 2rem;
  background-color: rgb(0, 255, 0);
}
.load{
  position: absolute;
  z-index: 999;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.an{
  animation-delay: 0.2s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
.an1{
  animation-name: g1;
}
.an2{
  animation-name: g2;
}
.an3{
  animation-name: g3;
}
.an4{
  animation-name: g4;
}
.an5{
  animation-name: g5;
}
.an6{
  animation-name: g6;
}
.an7{
  animation-name: g7
}
.an8{
  animation-name: g8;
}
.an9{
  animation-name: g9;
}

.an10{
  animation-name: g10;
}
@keyframes g1 {
  0%{
    width: 0;
  }
  100%{
    width: 10%;
  }
}
@keyframes g2 {
  0%{
    width: 10%;
  }
  100%{
    width: 20%;
  }
}
@keyframes g3 {
  0%{
    width: 20%;
  }
  100%{
    width: 30%;
  }
}
@keyframes g4 {
  0%{
    width: 30%;
  }
  100%{
    width: 40%;
  }
}
@keyframes g5 {
  0%{
    width: 40%;
  }
  100%{
    width: 50%;
  }
}
@keyframes g6 {
  0%{
    width: 50%;
  }
  100%{
    width: 60%;
  }
}
@keyframes g7 {
  0%{
    width: 60%;
  }
  100%{
    width: 70%;
  }
}
@keyframes g8 {
  0%{
    width: 70%;
  }
  100%{
    width: 80%;
  }
}
@keyframes g9 {
  0%{
    width: 80%;
  }
  100%{
    width: 90%;
  }
}
@keyframes g10 {
  0%{
    width: 90%;
  }
  100%{
    width: 100%;
  }
}
</style>