<template>
  <div class="ctn-e">
    <ProgressView :count="progressCount" :current="progressCurrent" @finish="finishProgress"></ProgressView>
    <div class="e-title">
      <div class="tit">个人信息</div>
      <div class="e-p user-face">
        <img id="good-img" class="user-face" :src="user.face" />
      </div>
      <div class="e-p">
        <input
          id="saveImage"
          cols="30"
          rows="10"
          type="file"
          accept="image/jpg, image/png"
          :onchange="imgOnChange"
        />
      </div>
      <div class="e-p">
        <span class="h">姓名：</span>
        <input id="name" cols="30" rows="10" v-model="user.name" />
      </div>
      <div class="e-p">
        <span class="h">性别：</span>
        <input cols="30" rows="10" v-model="user.gender" />
      </div>
      <div class="e-p">
        <span class="h">学历：</span>
        <input cols="30" rows="10" v-model="user.education" />
      </div>
      <div class="e-p">
        <span class="h">毕业院校：</span>
        <input cols="30" rows="10" v-model="user.college" />
      </div>
      <div class="e-p">
        <span class="h">个人简介：</span>
        <input cols="30" rows="10" v-model="user.description" />
      </div>
      <div class="e-p">
        <span class="h">电话：</span>
        <input cols="30" rows="10" v-model="user.phone" />
      </div>
      <div class="e-p">
        <span class="h">网站：</span>
        <input cols="30" rows="10" v-model="user.website" />
      </div>
      <div class="e-p">
        <span class="h">邮箱：</span>
        <input cols="30" rows="10" v-model="user.email" />
      </div>
    </div>

    <div class="e-title">
      <div class="tit">主要经历</div>
      <div class="e-p e-border" v-for="item in periods" :key="item">
        <div class="line">
          <span class="h">时间：</span>
          <input class="mid" cols="30" rows="10" v-model="item.time" />
        </div>
        <div class="line">
          <span class="h">地点：</span>
          <input class="mid" cols="30" rows="10" v-model="item.location" />
        </div>
        <div class="line">
          <span class="h">描述：</span>
          <input class="mid" cols="30" rows="10" v-model="item.description" />
        </div>
      </div>
    </div>
    <div class="e-title btn-pannel">
      <div class="btn" @click="save">save</div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
import ProgressView from "@/components/iframe/ProgressView.vue";
export default {
  components: { ProgressView },
  data() {
    return {
      user: {},
      periods: [],
      progressCount: 0,
      progressCurrent: 0
    };
  },

  mounted() {
    this.intiPage();
  },

  methods: {
    intiPage() {
      this.getUser();
      this.getPeriods();
    },
    getUser() {
      axios.get("/info/user/one").then(res => {
        this.user = res.data;
      });
    },
    getPeriods() {
      axios
        .post({ pageNum: 1, pageSize: 999 }, "/info/period/list")
        .then(res => {
          this.periods = res.data.list;
        });
    },
    save() {
      let count = 1 + this.periods.length;
      this.progressCount = count;
      this.progressCurrent = 0;
      axios.post(this.user, "/info/user/update").then(res => {
        if (res.data == 1) {
          this.progressCurrent = this.progressCurrent + 1;
        }
      });
      for (let index = 0; index < this.periods.length; index++) {
        const element = this.periods[index];
        axios.post(element, "/info/period/update").then(res => {
          if (res.data == 1) {
            this.progressCurrent = this.progressCurrent + 1;
          }
        });
      }
    },
    finishProgress() {
      this.progressCount = 0;
      this.progressCurrent = 0;
      this.intiPage();
    },
    /* 图片上传 */
    imgOnChange() {
      let file = $("#saveImage")[0].files[0];
      let url = URL.createObjectURL(file);
      console.log(url);
      axios.uploadImage(file, "/load/up/image").then(res => {
        console.log(res);
        this.uploadCallBack(res);
      });
    },
    /* 图片上传callBack */
    uploadCallBack(res) {
      if (res.code == 200) {
        //上传成功
        let url = res.data.path;
        $("#saveImage").attr("value", url);
        $("#good-img").attr("src", url);
        $("#upload-tip").addClass("un-show");
        this.user.face = url;
      }
    }
  }
};
</script>

<style scoped>
.user-face {
  width: 20rem;
  height: 20rem;
}
.e-border {
  border: solid 0.1rem rgb(255, 255, 255);
}
input {
  outline: none;
  border-style: solid;
  /* border-color: rgba(0, 0, 0, 0.226); */
  border-width: 0 0 0rem 0;
  font-size: 1rem;
  width: -webkit-fill-available;
  background-color: rgba(112, 112, 112, 0);
}
input:focus {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 0 0 0.1rem 0;
  background-color: rgba(253, 252, 252, 0.767);
}
.e-title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.137);
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0.356);
  padding-bottom: 1rem;
}
.tit {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
}
.e-p {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
}
.h {
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
}

.ctn-e {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn {
  background-color: rgb(96 96 96);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  width: 80%;
  border-radius: 1rem;
}
.btn:hover {
  zoom: 1.1;
}
.btn-pannel {
  padding: 0;
  margin-bottom: 10rem;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0);
  display: flex;
  align-items: center;
}
input.mid {
  text-align: center;
}
@media screen and (max-width: 900px) {
  .e-border {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .line {
    display: flex;
    width: 100%;
  }
}
</style>