<template>
  <div class="ctn-e">
    <div class="e-title">
      <div class="tit">主页</div>
      <div class="e-p">
        <img class="backimg good-img" :src="words.background" />
      </div>
      <div class="e-p">
        <span class="h">背景：</span>
        <input
          id="saveImage"
          cols="30"
          rows="10"
          type="file"
          accept="image/jpg, image/png"
          :onchange="imgOnChange"
        />
      </div>
      <div class="tit">主页描述</div>
      <div class="e-p">
        <span class="h">标题：</span>
        <input cols="30" rows="10" v-model="words.part1" />
      </div>
      <div class="e-p">
        <span class="h">内容：</span>
        <input cols="30" rows="10" v-model="words.part1Content" />
      </div>
    </div>

    <div class="e-title">
      <div class="tit">主页下描述</div>
      <div class="e-p">
        <span class="h">标题：</span>
        <input cols="30" rows="10" v-model="words.part2Title" />
      </div>
      <div class="e-p">
        <span class="h">内容：</span>
        <input cols="30" rows="10" v-model="words.part2Content" />
      </div>
    </div>

    <div class="e-title">
      <div class="tit">九宫格上描述</div>
      <div class="e-p">
        <span class="h">标题：</span>
        <input cols="30" rows="10" v-model="words.part3Title" />
      </div>
      <div class="e-p">
        <span class="h">内容：</span>
        <input cols="30" rows="10" v-model="words.part3Content" />
      </div>
    </div>

    <div class="e-title">
      <div class="tit">日志介绍</div>
      <div class="e-p">
        <span class="h">标题：</span>
        <input cols="30" rows="10" v-model="words.part4Title" />
      </div>
      <div class="e-p">
        <span class="h">内容：</span>
        <input cols="30" rows="10" v-model="words.part4Content" />
      </div>
    </div>
    <div class="e-title">
      <div class="tit">轮播文字</div>
      <div class="e-p" v-for="item in swiper" :key="item">
        <span class="h">标题：</span>
        <input cols="30" rows="item.words" v-model="item.words" />
        <button @click="deleteSwiper(item.id)">删除</button>
      </div>
      <div>
        <button @click="createSwiper">创建</button>
      </div>
    </div>

    <div class="e-title btn-pannel">
      <div class="btn" @click="save">save</div>
    </div>
    <ProgressView :count="progressCount" :current="progressCurrent" @finish="finishProgress"></ProgressView>
  </div>
</template>



<script>
import axios from "@/axios/request";
import ProgressView from "@/components/iframe/ProgressView.vue";
export default {
  components: { ProgressView },
  data() {
    return {
      words: {},
      swiper: [],
      progressCount: 0,
      progressCurrent: 0
    };
  },
  mounted() {
    this.initPageData();
  },
  methods: {
    createSwiper() {
      this.swiper.push({
        id: -1,
        words: ""
      });
    },
    deleteSwiper(id) {
      if (id == -1) {
        this.swiper.pop();
      } else {
        axios.post({ id: id }, "/index/words/swiper/delete").then(res => {
          console.log(res);
          if (res.data == 1) {
            this.initPageData();
          }
        });
      }
    },
    initPageData() {
      axios.get("/index/words/list").then(res => {
        this.words = res.data;
      });
      axios.get("/index/words/swiper/list").then(res => {
        this.swiper = res.data;
      });
    },
    finishProgress() {
      this.progressCount = 0;
      this.progressCurrent = 0;
      this.intiPage();
    },
    /* 图片上传 */
    imgOnChange() {
      let file = $("#saveImage")[0].files[0];
      let url = URL.createObjectURL(file);
      console.log(url);
      axios.uploadImage(file, "/load/up/image").then(res => {
        console.log(res);
        this.uploadCallBack(res);
      });
    },
    /* 图片上传callBack */
    uploadCallBack(res) {
      if (res.code == 200) {
        //上传成功
        let url = res.data.path;
        $("#saveImage").attr("value", url);
        // $("#good-img").attr("src", url);
        this.words.background = url;
      }
    },
    save() {
      //更新其他
      axios.post(this.words, "/index/words/update").then(res => {
        if (res.data == 1) {
          this.initPageData();
        }
      });
      //更新轮播图
      for (let index = 0; index < this.swiper.length; index++) {
        const element = this.swiper[index];
        if (element.id == -1) {
          axios.post(element, "/index/words/swiper/add").then(() => {
            this.initPageData();
          });
        } else {
          axios.post(element, "/index/words/swiper/update").then(() => {
            this.initPageData();
          });
        }
      }
      alert("更新完成");
    }
  }
};
</script>

<style scoped>
.backimg {
  width: 20rem;
  height: 20rem;
}
input {
  outline: none;
  border-style: solid;
  /* border-color: rgba(0, 0, 0, 0.226); */
  border-width: 0 0 0rem 0;
  font-size: 1rem;
  width: -webkit-fill-available;
  background-color: rgba(112, 112, 112, 0);
}
input:focus {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 0 0 0.1rem 0;
  background-color: rgba(253, 252, 252, 0.767);
}
.e-title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.137);
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0.356);
  padding-bottom: 1rem;
}
.tit {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
}
.e-p {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}
.h {
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
}

.ctn-e {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn {
  background-color: rgb(96 96 96);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  width: 80%;
  border-radius: 1rem;
}
.btn:hover {
  zoom: 1.1;
}
.btn-pannel {
  padding: 0;
  margin-bottom: 10rem;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0);
  display: flex;
  align-items: center;
}
button {
  width: 4rem;
  margin-left: 0.5rem;
}
</style>