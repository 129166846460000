<template>
  <div class="ctn flex">
    <div class="top-box flex">
      <div class="face">
        <img class="face" src="@/assets/img/biz/11.jpeg" />
      </div>
      <div class="info">
        <div class="c-name line">
          <div class="t">姓名：</div>
          <div class="name-d d">{{ user.name }}</div>
        </div>
        <div class="c-des line">
          <div class="t">性别：</div>
          <div class="gender-d d">{{ user.gender==0?'女':'男' }}</div>
        </div>
        <div class="line">
          <div class="t">学历：</div>
          <div class="d">{{ user.education }}</div>
        </div>
        <div class="line">
          <div class="t">毕业院校：</div>
          <div class="d">{{ user.college }}</div>
        </div>
        <div class="line">
          <div class="t">个人简介：</div>
          <div class="d">{{ user.description }}</div>
        </div>
        <div class="line">
          <div class="t">电话：</div>
          <div class="d">{{ user.phone }}</div>
        </div>
        <div class="line">
          <div class="t">网站：</div>
          <div class="d">{{ user.website }}</div>
        </div>
        <div class="line">
          <div class="t">邮箱：</div>
          <div class="d">{{ user.email }}</div>
        </div>
      </div>
    </div>
    <div class="period flex">
      <div class="d-item" v-for="item in periods" :key="item">
        <div class="left">
          <div class="time flex">{{ item.time }}</div>
          <div class="part part-l flex">
            <div class="dot"></div>
          </div>
        </div>
        <div class="right">
          <div class="doc doc-name">{{ item.location }}</div>
          <div class="doc doc-des">{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      user: {
      },
      periods: [
      ]
    };
  },
  mounted() {
    this.initPage()
  },
  methods: {
    initPage(){
      let that = this
      axios.post({"pageNum":1,"pageSize":999},"/info/period/list").then(res=>{
        console.log(res)
        that.periods = res.data.list
      })
      axios.get("/info/user/one").then(res=>{
        console.log(res)
        that.user = res.data
      })
    }
  },
};
</script>

<style scoped>
.period {
    flex-direction: column;
    margin-top:2rem;
}
.ctn {
  padding-top: 12rem;
  color: black;
  flex-direction:column;
}
.t{
  white-space: nowrap;
}
.line {
  display: flex;
}
.face {
  width: 10rem;
  margin-right: 1rem;
}

.d-item {
  margin-left: 2rem;
  display: flex;
  width: 100%;
}
.dot {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: rgb(104, 104, 104);
  border-radius: 50%;
  margin-right: -0.5rem;
}
.d-item.active > .left > .part > .dot {
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgb(0, 0, 0);
  margin-right: -0.5rem;
}
.d-item.active > .left > .time {
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.d-item.active > .right>a {
  color: rgb(0, 0, 0);
}
.d{
  text-align: left;
}
.part {
  width: 0.5rem;
  height: 100%;
}
.part-l {
  border-width: 0 1px 0 0;
  border-color: black;
  border-style: solid;
}

.left {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: end;
  cursor: pointer;
}
a{
  color: rgb(96 96 96);
}
.right {
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  cursor: pointer;
  padding-right: 2rem;
}
.doc {
  width: 100%;
  text-align: start;
}
.doc-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.ctn {
  color: black;
  padding-top: 12rem;
  display: flex;
  flex-direction: column;
}
.time {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
  word-break: keep-all;
  white-space: nowrap;
}
@media screen and (max-width: 990px){
  .top-box{
    flex-direction: column;
  }
  .info{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .line{
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    align-items: baseline;
  }
  .t{
    font-size:0.9rem;
    font-weight: bold;
    color: rgb(34, 34, 34);
  }
  .d{
    font-size: 1rem;
  }
}
</style>