<template>
  <div class="ctn ctn-1">
    <div class="top flex">
      <div class="top-l">
        <div class="div-new-img">
          <img class="new-img" :src="defaultImg.img==undefined?require('@/assets/add.png'):defaultImg.img"/>
        </div>
        <input
          id="saveImage"
          cols="30"
          rows="10"
          type="file"
          accept="image/jpg, image/png"
          :onchange="imgOnChange"
        />
      </div>
      <div class="top-r">
        <div class="h">
          <span>名称：</span>
          <input type="text" v-model="defaultImg.name" />
        </div>
        <div class="h">
          <span>描述：</span>
          <input type="text" v-model="defaultImg.description" />
        </div>
        <div class="h">
          <span>分类：</span>
          <select class="se1" id="createSelect" @change="createStyleChange()">
            <option  value="-1" >请选择图片归属分类</option>
            <option v-for="(item) in styles" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <button @click="clear()">清空</button>
      <button @click="create()">保存</button>
    </div>
    <div class="bottom">
      <select class="se2" id="select-1" @change="styleChange()">
        <option value="-1">查看全部</option>
        <option v-for="(item) in styles" :key="item.id" :value="item.id">{{ item.name }}</option>
      </select>
      <div class="imgs">
        <div class="img-box" v-for="(item,index) in styleImgs" :key="item.id">
          <div class="w wh">
            <div class="flex" @click="editImg(index)">编辑</div>
          </div>
          <img :src="item.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/request";
export default {
  data() {
    return {
      styles: [],
      defaultImg: {},
      styleImgs: []
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    clear(){
        this.defaultImg={}
    },
    create() {
      let id = this.defaultImg.styleid;
      if (id == undefined) {
        alert("请先选中图片所属类型");
      } else {
        if (this.defaultImg.id != undefined) {
          //更新
          axios.post(this.defaultImg, "/style/img/update").then(res => {
            if (res.data == 1) {
              alert("更新成功");
              this.defaultImg = {};
              this.initPage();
            }
          });
        } else if (this.defaultImg.id == undefined) {
          axios.post(this.defaultImg, "/style/img/add").then(res => {
            if (res.data == 1) {
              alert("创建成功");
              this.defaultImg = {};
              this.initPage();
            }
          });
        }
      }
    },
    editImg(index) {
      this.defaultImg = this.styleImgs[index];
    },
    initPage() {
      axios.get("/styles/list").then(res => {
        this.styles = res.data;
      });
      axios.post({ pageNum: 1, pageSize: 999 }, "/style/img/list").then(res => {
        this.styleImgs = res.data.list;
      });
    },
    styleChange() {
      let id = $("#select-1").val();
      let param = { pageNum: 1, pageSize: 999 };
      if (id != -1) {
        param.condition = id;
      }
      axios.post(param, "/style/img/list").then(res => {
        this.styleImgs = res.data.list;
      });
    },
    createStyleChange() {
      let id = $("#createSelect").val();
      this.defaultImg.styleid = id;
    },

    /* 图片上传 */
    imgOnChange() {
      let file = $("#saveImage")[0].files[0];
      let url = URL.createObjectURL(file);
      console.log(url);
      axios.uploadImage(file, "/load/up/image").then(res => {
        console.log(res);
        this.uploadCallBack(res);
      });
    },
    /* 图片上传callBack */
    uploadCallBack(res) {
      if (res.code == 200) {
        //上传成功
        let url = res.data.path;
        $("#saveImage").attr("value", url);
        $("#good-img").attr("src", url);
        $("#upload-tip").addClass("un-show");
        this.defaultImg.img = url;
      }
    }
  }
};
</script>

<style scoped>
.ctn {
  color: black;
  flex-direction: column;
}
.top-r {
  margin-left: 1rem;
}
.top {
  padding: 1rem;
  align-items: self-start;
}
select.se1 {
  width: 10rem;
  margin-bottom: 1rem;
}
select.se2 {
  width: 20rem;
  margin-bottom: 1rem;
}
.main-panel {
  overflow: hidden;
}
.div-new-img {
  width: 10rem;
  height: 10rem;
  border: solid 0.1rem black;
  margin-bottom: 1rem;
}
.new-img {
  width: 10rem;
  height: 10rem;
}
#saveImage {
  width: 6rem;
  margin-bottom: 1rem;
}
button {
  margin-bottom: 1rem;
}
.h {
  margin-bottom: 1rem;
}
input {
  outline: none;
}

@media screen and (max-width: 990px) {
  .top {
    flex-direction: column;
  }
  .wh > div[data-v-8cd6ab82] {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 0.2rem 1rem;
    border-radius: 0.1rem;
    display: flex;
    word-break: keep-all;
  }
}
.info {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.title {
  font-size: 2rem;
  font-weight: bold;
}
.imgs {
  column-count: 7;
  column-gap: 0;
  position: relative;
}
.img-box {
  margin-right: 1rem;
  position: relative;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.w {
  position: absolute;
  visibility: hidden;
}
.img-box:hover > .w {
  visibility: inherit;
}
.wh {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh > div {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  display: flex;
}
.wb {
  padding: 0.2rem 2rem;
  border-radius: 1rem;
  background-color: white;
  color: #000;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}
img {
  width: 100%;
}
.des {
  padding: 1rem;
}

@media screen and (max-width: 900px) {
  .imgs {
    column-count: 3;
    column-gap: 0;
    width: 95vw;
  }
}
.ctn-1 {
  height: 100%;
  widows: 100%;
  overflow: scroll;
}
</style>