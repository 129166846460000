<template>
  <div class="ctn">
    <div class="info">
      <div class="title">{{style.name}}</div>
      <div
        class="des">{{ style.description }}</div>
    </div>
    <div class="imgs">
      <div class="img-box" v-for="item in imgs" :key="item" @click="openImgView(item.img)">
        <div class="w wh">
          <div class="flex">{{item.name}}</div>
        </div>
        <div class="w wb flex">
          <div class="flex">查看大图</div>
        </div>
        <img :src="item.img" />
      </div>
    </div>
    <ImgView ref="imgView" :showAble="showAble" :path="path" @clossImgView="clossImgView"></ImgView>
  </div>
</template>

<script>
import ImgView from "../iframe/ImgView.vue";
import axios from "@/axios/request";
export default {
  components: { ImgView },
  data() {
    return {
      id: -1,
      path: require("@/assets/img/biz/1.jpg"),
      showAble: false,
      style:{},
      imgs: []
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      let that = this
      this.id = this.$route.query.id;
      axios.post({id:this.id},"/styles/one").then(res=>{
        console.log(res)
        that.style = res.data
      })
      axios.post({"pageNum":1,"pageSize":999,"condition":this.id},"/style/img/list").then(resa=>{
        that.imgs = resa.data.list
      })
    },
    clossImgView() {
      this.showAble = false;
    },
    openImgView(path) {
      this.showAble = true;
      this.path = path;
      this.$refs.imgView.open();
    }
  }
};
</script>

<style scoped>
.ctn {
  padding-top: 10rem;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.title {
  font-size: 2rem;
  font-weight: bold;
}
.imgs {
  column-count: 7;
  column-gap: 0;
  width: 95vw;
  position: relative;
}
.img-box {
  margin-right: 1rem;
  position: relative;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  margin-right: 1rem;
  cursor: pointer;
}
.w {
  position: absolute;
  visibility: hidden;
}
.img-box:hover > .w {
  visibility: inherit;
}
.wh {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh > div {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  display: flex;
}
.wb {
  padding: 0.2rem 2rem;
  border-radius: 1rem;
  background-color: white;
  color: #000;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}
img {
  width: 100%;
}
.des {
  padding: 1rem;
}

@media screen and (max-width: 900px) {
  .imgs {
    column-count: 3;
    column-gap: 0;
    width: 95vw;
  }
}
</style>