<template>
  <div class="ctn">
    <div class="top">
      <div class="title">{{words.part4Title}}</div>
      <div class="detail">{{ words.part4Content }}</div>
    </div>

    <div class="panel">
      <swiper
        :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
      }"
        class="mySwiper"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @progress="1"
        :loop="true"
        :autoplay="autoplay"
        :spaceBetween="20"
        :modules="modules"
        :slidesPerView="isMobole?1:3"
        :centeredSlides="true"
        :pagination="{ clickable:true}"
        @setTransition="1"
      >
        <swiper-slide class="no-select" v-for="item in list" :key="item">
          <div class="pro-box cursor">
            <div class="top pro-img">
              <img class="pro-img" :src="item.cover" />
            </div>
            <div class="bottom">
              <div class="pro-name">{{item.name}}</div>
              <div class="pro-detail">{{item.description}}</div>
              <div class="pro-time">{{ format( item.createtime) }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import _isMobile from "@/assets/js/tools";
import axios from "@/axios/request";
export default {
  props: {
    words: {
      type: Object
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isMobole: false,
      modules: [Navigation, Pagination],
      autoplay: {
        delay: 1500
      },
      list: []
    };
  },
  setup() {
    const onSwiper = () => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange
    };
  },
  mounted() {
    this.isMobole = _isMobile();
    this.initPage();
  },
  methods: {
    initPage() {
      axios.post({}, "/diary/list").then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    format(timestamp) {
      var time = new Date(timestamp);
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      return year + "-" + month + "-" + date;
    }
  }
};
</script>

<style scoped>
.ctn {
  color: black;
  display: flex;
  flex-direction: column;
}
.pro-detail {
  width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden;
}
.swiper-pagination {
  bottom: 83px;
}
.pro-name {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.top {
  width: 100%;
  padding-bottom: 2rem;
}
.detail {
  margin-left: 2rem;
  margin-right: 2rem;
}
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.swiper {
  position: static;
  width: 60%;
  height: inherit;
  padding-bottom: 2rem;
}
.swiper-pagination {
  margin-top: 2rem;
}
.pro-img {
  width: 15vw;
  height: 15vw;
}
.pro-box {
  width: 15vw;
  height: 15vw;
  display: flex;
  flex-direction: column;
}
.top {
  padding-bottom: 1rem;
}
.bottom {
  display: flex;
  flex-direction: column;
}
.title {
  margin-top: 3rem;
}
.panel {
  height: 30vw;
}
@media screen and (max-width: 950px) {
  .pro-img {
    width: 60vw;
    height: 60vw;
  }

  .pro-box {
    width: 60vw;
  }
  .panel {
    height: 110vw;
  }
  .swiper {
    height: inherit;
  }
}
</style>
