<template>
  <div class="ctn-e">
    <div class="e-title">
      <div class="e-p">
        <img class="img" id="cover" :src="diary.cover" />
      </div>
      <div class="e-p">
        <span class="h">封面：</span>
        <input
          id="saveImage"
          cols="30"
          rows="10"
          type="file"
          accept="image/jpg, image/png"
          :onchange="imgOnChange"
        />
      </div>
      <div class="e-p">
        <span class="h">名称：</span>
        <input name="homeE" id="homeE" cols="30" rows="10" v-model="diary.name" />
      </div>
      <div class="e-p">
        <span class="h">描述：</span>
        <input name="homeE" id="homeE" cols="30" rows="10" v-model="diary.description" />
      </div>
      <div class="e-p">
        <span class="h">正文：</span>
        <div class="trumbowyg">
          <textarea id="editor" v-model="diary.content"></textarea>
        </div>
      </div>
      <div class="e-p">
        <button>置顶</button>
        <button>删除</button>
      </div>
    </div>
    <div class="e-title btn-pannel">
      <div class="btn" @click="save">save</div>
    </div>
  </div>
</template>
  
  
  
  <script>
import axios from "@/axios/request";
export default {
  props:{
    diaryId:{
      type:Number,
      require:false,
      default: -1
    }
  },
  data() {
    return {
      diary:{}
    };
  },
  mounted() {
    if ($(".trumbowyg").length == 1) {
      $("#editor").trumbowyg();
    }
    this.initPage();
  },
  methods: {
    initPage(){
      if(this.diaryId!=-1){
        axios.post({"id":this.diaryId},"/diary/one").then(res=>{
          this.diary = res.data
          this.applyContent(this.diary.content)
        })
      }
    },
    applyContent(content){
      $(".trumbowyg-editor").html(content)
    },
    save(){
      let id = this.diary.id
      let content = $(".trumbowyg-editor").html()
      this.diary.content = content
      if(undefined == id){
        axios.post(this.diary,"/diary/add").then((res)=>{
          if(res.data==1){
            alert("创建成功")
            this.$emit("finishDiary")
          }
        })
      }else{
        axios.post(this.diary,"/diary/update").then((res)=>{
          if(res.data==1){
            alert("编辑成功")
            this.$emit("finishDiary")
          }
        })
      }
    },
    /* 图片上传 */
    imgOnChange() {
      let file = $("#saveImage")[0].files[0];
      let url = URL.createObjectURL(file);
      console.log(url);
      axios.uploadImage(file, "/load/up/image").then(res => {
        console.log(res);
        this.uploadCallBack(res);
      });
    },
    /* 图片上传callBack */
    uploadCallBack(res) {
      if (res.code == 200) {
        //上传成功
        let url = res.data.path;
        $("#saveImage").attr("value", url);
        $("#cover").attr("src", url);
        this.diary.cover = url;
      }
    }
  }
};
</script>
  
<style >
.trumbowyg {
  width: 100%;
}
.trumbowyg-box .trumbowyg-editor {
  background-color: white;
  width: 100%;
}
</style>
  <style scoped>
@import "@/assets/css/trumbowyg.css";
input {
  outline: none;
  border-style: solid;
  /* border-color: rgba(0, 0, 0, 0.226); */
  border-width: 0 0 0rem 0;
  font-size: 1rem;
  width: -webkit-fill-available;
  background-color: rgba(112, 112, 112, 0);
}
input:focus {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 0 0 0.1rem 0;
  background-color: rgba(253, 252, 252, 0.767);
}
.e-title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.137);
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0.356);
  padding-bottom: 1rem;
}
.tit {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
}
.e-p {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}
.h {
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
}

.ctn-e {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn {
  background-color: rgb(96 96 96);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  width: 80%;
  border-radius: 1rem;
}
.btn:hover {
  zoom: 1.1;
}
.btn-pannel {
  padding: 0;
  margin-bottom: 10rem;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(92, 92, 92, 0);
  display: flex;
  align-items: center;
}
el-select {
  width: 5rem;
}
button {
  margin-right: 2rem;
}
img {
  width: 16rem;
  height: 16rem;
}
</style>