<template>
  <div class="container">
    <div class="header-nv">
      <div>
        <img v-if="logo" class="loge" src="@/assets/loge-w.png" />
        <img v-if="!logo" class="loge" src="@/assets/logo-b.png" />
      </div>
      <div class="c-ul">
        <ul class="comp-menu">
          <router-link to="/">
            <li>主页</li>
          </router-link>
          <router-link to="/case">
            <li>案例</li>
          </router-link>
          <router-link to="/diarys">
            <li>日志</li>
          </router-link>
          <router-link to="/contact">
            <li>联系我</li>
          </router-link>
          <router-link to="/edit">
            <li>Edit</li>
          </router-link>
        </ul>
        <ul class="mobel-menu">
          <img class="more" v-if="logo" src="@/assets/more-b.png" v-on:click="showMenu" />
          <img class="more" v-if="!logo" src="@/assets/more-w.png" v-on:click="showMenu" />
        </ul>
      </div>
      <div class="menu">
        <div class="ul-menu" v-if="menuShow">
          <router-link to="/" class="ul-spli">
            <div class="li-menu">主页</div>
          </router-link>
          <router-link to="/case" class="ul-spli">
            <div class="li-menu">案例</div>
          </router-link>
          <router-link to="/diarys" class="ul-spli">
            <div class="li-menu">日志</div>
          </router-link>
          <router-link to="/contact" class="ul-spli">
            <div class="li-menu">联系我</div>
          </router-link>
          <router-link to="/edit">
            <div class="li-menu">edit</div>
          </router-link>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import _isMobole from "@/assets/js/tools";
export default {
  name: "App",
  data() {
    return {
      menuShow: false,
      flag: false,
      logo: true
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    if (_isMobole()) {
      $(".d3").addClass("hover");
    }
  },
  methods: {
    showMenu() {
      this.menuShow = !this.menuShow;
    },
    handleScroll() {
      this.menuShow = false;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop >= 60) {
        $("a").removeClass("lose");
        $("a").addClass("active");
        $(".header-nv").removeClass("lose");
        $(".header-nv").addClass("active");
        this.flag = true;
        this.logo = false;
      } else if (scrollTop < 60 && this.flag) {
        $("a").removeClass("active");
        $("a").addClass("lose");
        $(".header-nv").removeClass("active");
        $(".header-nv").addClass("lose");
        this.logo = true;
      }
    }
  },
  created() {}
};
</script>
<style >
.hidden{
  display: none!important;
}
a {
  text-decoration: none;
}
.cursor{
  cursor: pointer;
}
.no-select {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
img{
  max-width: 90vw;
}
</style>
<style scoped>
.more {
  width: 3rem;
  height: 3rem;
}
.ctn {
  position: relative;
}
.bg-image {
  z-index: -1;
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(@/assets/home-7.jpg);
}
.bg-image-overlay {
  position: absolute;
  background-color: rgba(12, 12, 12, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tb {
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header-nv {
  background-color: rgb(0, 0, 0);
  width: 100%;
  position: absolute;
  top: 60px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
  left: 0;
}

.header-nv.active {
  position: fixed;
  top: 0;
  color: black;
  animation: colorTrange;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.header-nv.lose {
  animation: colorTrange-lose;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
a.active {
  animation: ac-lose;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

a.lose {
  animation: ac;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
@keyframes ac {
  0% {
    color: rgb(0, 0, 0);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}
@keyframes ac-lose {
  0% {
    color: rgba(255, 255, 255, 1);
  }
  100% {
    color: rgb(0, 0, 0);
  }
}

@keyframes colorTrange {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@keyframes colorTrange-lose {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgb(0, 0, 0);
  }
}
.loge {
  width: 7rem;
}
.header-nv > div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  float: left;
  list-style: none;
  margin-left: 5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  width: 25%;
  color: #ffffff;
}

.footer {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  padding-top: 75px;
}
.d3 {
  float: top;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-top-color: #b0b0b059;
  margin-bottom: 2rem;
}
.mobel-menu {
  display: none;
}
.comp-menu {
  display: inline-flex;
  word-break: keep-all;
}
.d3:hover {
  animation: backcolorT;
  animation-duration: 1s;
  animation-iteration-count: 103;
  animation-fill-mode: both;
}
.hover {
  animation: backcolorT;
  animation-duration: 1s;
  animation-iteration-count: 103;
  animation-fill-mode: both;
}

@keyframes backcolorT {
  0% {
    border-top-color: #b0b0b03b;
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    border-top-color: #b0b0b052;
  }
}
.info {
  font-size: 2rem;
}
.menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.ul-menu {
  display: flex;
  background-color: white;
  color: #624b4bd9;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.ul-spli {
  border-right: solid 1px;
}

@media screen and (max-width: 992px) {
  a {
    margin: 0;
    color: black;
  }
  .info[data-v-21bfc292] {
    font-size: 2rem;
  }
  .mobel-menu {
    display: block;
  }
  .comp-menu {
    display: none;
  }
  .header-nv {
    height: 2rem;
  }
  .ul-menu {
    bottom: -2rem;
    position: absolute;
  }
}
</style>
<style>
* {
  font-family: "Microsoft YaHei";
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
*,
:after,
:before {
  box-sizing: inherit;
}
.container {
  position: relative;
}
.ctn {
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
}
body {
  margin: 0;
}
</style>
