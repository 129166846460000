<template>
  <div class="ctn">
    <div class="bg-image">
      <div class="bg-image-overlay"></div>
    </div>
    <div class="tb">
      <div></div>
      <div class="info">
        {{ words.part1 }}
        <div class="des">{{ words.part1Content }}</div>
      </div>
      <div class="footer">
        <div class="d3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _isMobole from "@/assets/js/tools";
export default {
  props: {
    words: {
      type: Object
    }
  },

  data() {
    return {};
  },
  mounted() {
    if (_isMobole()) {
      $(".d3").addClass("hover");
    }
  },
  methods: {},
  created() {}
};
</script>

<style  scoped>
.more {
  width: 3rem;
  height: 3rem;
}
.ctn {
  position: relative;
  z-index: 2;
}
.des{
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: 1rem;
}
.tb {
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header-nv {
  width: 100%;
  position: absolute;
  top: 60px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
  left: 0;
}

.header-nv.active {
  position: fixed;
  top: 0;
  color: black;
  animation: colorTrange;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.header-nv.lose {
  animation: colorTrange-lose;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
@keyframes colorTrange {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@keyframes colorTrange-lose {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
.loge {
  width: 7rem;
}
.header-nv > div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
li {
  float: left;
  list-style: none;
  margin-left: 5rem;
  font-size: 1rem;
  cursor: pointer;
}
li:hover {
  color: white;
}
.footer {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  padding-top: 75px;
}
.d3 {
  float: top;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-top-color: #b0b0b059;
  margin-bottom: 2rem;
}
.mobel-menu {
  display: none;
}
.comp-menu {
  display: block;
}
.d3:hover {
  animation: backcolorT;
  animation-duration: 1s;
  animation-iteration-count: 103;
  animation-fill-mode: both;
}
.hover {
  animation: backcolorT;
  animation-duration: 1s;
  animation-iteration-count: 103;
  animation-fill-mode: both;
}

@keyframes backcolorT {
  0% {
    border-top-color: #b0b0b03b;
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    border-top-color: #b0b0b052;
  }
}
.info {
  font-size: 2rem;
}
.menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.ul-menu {
  display: flex;
  background-color: white;
  color: #624b4bd9;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.ul-spli {
  border-right: solid 1px;
}
.li-menu {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  width: 25%;
}
@media screen and (max-width: 992px) {
  .info[data-v-21bfc292] {
    font-size: 2rem;
  }
  .mobel-menu {
    display: block;
  }
  .comp-menu {
    display: none;
  }
  .header-nv {
    height: 2rem;
  }
  .ul-menu {
    bottom: -2rem;
    position: absolute;
  }
}
</style>
<style >
.bg-image {
  z-index: -1;
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(@/assets/home-7.jpg);
}
.bg-image-overlay {
  position: absolute;
  background-color: rgba(12, 12, 12, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>