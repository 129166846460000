import {
    createRouter,
    createWebHistory
} from "vue-router";
//引入组件
import MyContainer from '@/components/front/MyContainer.vue'
import Edit from "@/components/back/PageEdit.vue"
import Login from "@/components/back/PageLogin.vue"
import PageCase from "@/components/front/PageCase.vue"
import PageCaseDetail from "@/components/front/PageCaseDetail"
import PageDiaryList from "@/components/single/PageDiaryList"
import PageDiary from "@/components/single/PageDiary"
import PageMine from "@/components/single/PageMine"


import LocalStorageF from '@/storage/index'
const routes = [
    {
        path: "/",
        component: MyContainer,
    },
    {
        path:"/edit",
        component:Edit,
        meta:{
            isAuth:true
        }
    },
    {
        path:"/login",
        component:Login
    },
    {
        path:"/case",
        component:PageCase
    },
    {
        path:"/caseDetail",
        component:PageCaseDetail
    },
    {
        path:"/diarys",
        component:PageDiaryList
    },
    {
        name:"Diary",
        path:"/diary",
        component:PageDiary
    },
    {
        path:"/contact",
        component:PageMine
    },
    
    
]
//创建一个路由器

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) {
        if (LocalStorageF.tokenExist()) {
            next();
        } else {
            router.push("login")
        }
    }else{
        next();
    }

})

export default router