<template>
  <div class="ctn">
    <PageD :words="words"></PageD>
    <PageE></PageE>
  </div>
</template>

<script>
import axios from "@/axios/request";
import PageE from "@/components/front/PageE.vue";
import PageD from "@/components/front/PageD.vue";
export default {
  components: { PageE, PageD },
  data() {
    return {
      words: {}
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      let that = this;
      axios.get("/index/words/list").then(res => {
        that.words = res.data;
      });
    }
  }
};
</script>

<style scoped>
.ctn {
  padding-top: 10rem;
  color: black;
  display: flex;
  flex-direction: column;
}
</style>