import axios from "axios";
// axios.defaults.baseURL = ''  //正式
axios.defaults.baseURL = 'https://api.yiz2022.com' //测试

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

// 表示跨域请求时是否需要使用凭证 允许跨域携带cookie信息
axios.defaults.withCredentials = false;

// 允许跨域
// axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // config.headers.common.token ="-1"
        return config;
        
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(JSON.stringify(error), '请求异常', {
            confirmButtonText: '确定',
            callback: (action) => {
                console.log(action)
            }
        });
    }
);
export default {
    /**
     * @param {String} url 
     * @param {Object} data 
     * @returns Promise
     */
    post(data,url) {
        return new Promise((resolve, reject) => {
          
            axios({
                    method: 'post',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // uploadImage(img, url,uploadCallBack) {
    //     console.log("=====================start upload requist===============")
    //     let param = new FormData(); //创建form对象
    //     param.append('multipartFile',img);
    //     param.append('file',img);
    //     axios({
    //         url: url,
    //         method: 'post',
    //         baseURL: axios.defaults.baseURL,
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         },
    //         data: param,
    //         timeout: 1000
    //     }).then(res => {
    //         uploadCallBack(res.data);
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // },
    uploadImage(img,url){
        let param = new FormData(); //创建form对象
        param.append('multipartFile',img);
        param.append('file',img);
        return new Promise((resolve,reject)=>{
            axios({
                url,
                method:'post',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: param,
                timeout: 1000
            }).then(res=>{
                resolve(res.data)
            }).catch(res=>{
                reject(res.data)
            })
        })
    }
};

