import { createApp } from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// 导入axios
import axios from "./axios/request"
createApp(App).config.globalProperties.$axios = axios;

//富文本编辑器
import  trumbowyg from "@/assets/js/trumbowyg"
createApp(App).config.globalProperties.$trumbowyg = trumbowyg;
import trumbConfig from "@/assets/js/config_file"
createApp(App).config.globalProperties.$trumbConfig = trumbConfig;
import trumbUpload from "@/assets/js/trumbowyg.upload"
createApp(App).config.globalProperties.$trumbUpload = trumbUpload;

//引入路由器
import router from "./router/index"

createApp(App).config.productionTip = false

createApp(App).use(router).use(VueAwesomeSwiper).mount('#app')
