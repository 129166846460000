<template>
  <div class="ctn">
    <div class="title">{{ words.part3Title }}</div>
    <div class="detail">
      {{words.part3Content}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    words: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log(this.words)
  },
  methods: {
   
  },
};
</script>

<style scoped>
.ctn{
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: black;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}
.title{
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.detail{
  font-size: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>